import React, {useState, useEffect, useContext, lazy} from 'react';

import {LPHandlerContext} from "../context/LPHandlerProvider"

const TempHeroImage = lazy(() => import('./Temp_Hero_Image/Index'));
const Vaughnorthodontics = lazy(() => import('./Vaughnorthodontics/Index'));
const VanNewPatients = lazy(() => import('./VanNewPatients/Index'));
const TempJoeFM = lazy(() => import('./TempJoeFM/Index'));
const TempAprilLarsen = lazy(() => import('./TempAprilLarsen/Index'));
const TempFlemmingLarsen = lazy(() => import('./TempFlemmingLarsen/Index'));
const TempGreenTech = lazy(() => import('./TempGreenTech/Index'));
const TempTest = lazy(() => import('./Temp_Test/Index'));
const Userlogin = lazy(() => import('./Comman/Userlogin'));
const Notfound = lazy(() => import('./Comman/Notfound'));
const ContactCard = lazy(() => import('./Comman/ContactCard'));

const TempSelector = props => {
	
    const lpvals = useContext(LPHandlerContext);
	
    const [template, setTemplate] = useState();
	
	//console.log(lpvals);
	
    useEffect(() => {
        if(lpvals.temp_name !== undefined && lpvals.temp_name !=="") {
			
            let temp = null;
            if (lpvals.temp_name === 'Login') {
                temp = <Userlogin temp_name={lpvals.temp_name}/>;
            }else if (lpvals.temp_name === 'TempJoeFM') {
                temp = <TempJoeFM temp_name={lpvals.temp_name} client_id={lpvals.client_id} lp_id={lpvals.lp_id} s_id={lpvals.sid}/>;
            }else if (lpvals.temp_name === 'TempAprilLarsen') {
                temp = <TempAprilLarsen temp_name={lpvals.temp_name} client_id={lpvals.client_id} lp_id={lpvals.lp_id} s_id={lpvals.sid}/>;
            }else if (lpvals.temp_name === 'TempFlemmingLarsen') {
                temp = <TempFlemmingLarsen temp_name={lpvals.temp_name} client_id={lpvals.client_id} lp_id={lpvals.lp_id} s_id={lpvals.sid}/>;
            }else if (lpvals.temp_name === 'TempGreenTech') {
                temp = <TempGreenTech temp_name={lpvals.temp_name} client_id={lpvals.client_id} lp_id={lpvals.lp_id} s_id={lpvals.sid}/>;
            }else if (lpvals.temp_name === 'Contact_Card') {
                temp = <ContactCard temp_name={lpvals.temp_name} client_id={lpvals.client_id} lp_id={lpvals.lp_id}/>;
            } else if (lpvals.temp_name === 'Temp_Hero_Image') {
                temp = <TempHeroImage temp_name={lpvals.temp_name} client_id={lpvals.client_id} lp_id={lpvals.lp_id}/>;
            } else if (lpvals.temp_name === 'Vaughnorthodontics') {
                temp = <Vaughnorthodontics temp_name={lpvals.temp_name} client_id={lpvals.client_id} lp_id={lpvals.lp_id}/>;
            } else if (lpvals.temp_name === 'VanNewPatients') {
                temp = <VanNewPatients temp_name={lpvals.temp_name} client_id={lpvals.client_id} lp_id={lpvals.lp_id}/>;
            } else if (lpvals.temp_name === 'Temp_Test') {
                temp = <TempTest temp_name={lpvals.temp_name}/>;
            } else {
                temp = <Notfound temp_name={lpvals.temp_name}/>;
            }
            setTemplate(temp)
        }
    }, [lpvals]);

    return (
        <>
			{template}
        </>
    );
}

export default TempSelector;